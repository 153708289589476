<!-- Button -->
<div class="flex items-center header-profile-image" [skeleton]="isLoading">
    <p class="font-semibold pr-2 text-secondary">
        {{ user.firstName + ' ' + user.lastName }}
    </p>
    <button
        data-cy="userIcon"
        mat-icon-button
        [matMenuTriggerFor]="userActions"
    >
        <div class="relative mb-0" [skeleton]="isLoading">
            <img
                class="w-7 h-7 rounded-full"
                *ngIf="showAvatar && user.profilePic"
                [src]="user.profilePic"
            />
            <mat-icon
                *ngIf="!showAvatar || !user.profilePic"
                [svgIcon]="'heroicons_outline:user-circle'"
            ></mat-icon>
        </div>
    </button>
</div>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <small>{{ 'app.SIGNED_IN_AS' | translate }}</small>
            <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item [routerLink]="['/profile']">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>{{ 'app.PROFILE' | translate }}</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button data-cy="signOut" mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>{{ 'app.SIGN_OUT' | translate }}</span>
    </button>
</mat-menu>
