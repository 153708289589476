import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent
} from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { UserService } from '../../../../core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Location } from '@angular/common';
import { NavItemId } from '../../../../mock-api/common/navigation/data';
import {
    CompanyFeature,
    CompanyWithPermissions
} from '../../../../../api/models';
import { companyHasFeature } from '../../../../core/auth/auth.helper';

type DropdownIcon = 'mat_solid:arrow_drop_down' | 'mat_solid:arrow_drop_up';

const companyProtectedRoutes = ['/finance'];

@Component({
    selector: 'compact-layout',
    templateUrl: './compact.component.html',
    styleUrls: ['./compact.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CompactLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isCompanyScoped = false;

    public dropdownIcon: DropdownIcon = 'mat_solid:arrow_drop_down';

    public user: User;
    public activeCompany: CompanyWithPermissions;
    protected readonly NavItemId = NavItemId;

    /**
     * Constructor
     */
    constructor(
        public _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        public _userService: UserService,
        public location: Location
    ) {
        this.checkIfCompanyScoped(this._router.url);
        this.location.onUrlChange((url) => {
            this.checkIfCompanyScoped(url);
        });
    }

    private checkIfCompanyScoped(url: string) {
        this.isCompanyScoped = false;
        for (const protectedRoute of companyProtectedRoutes) {
            if (url.includes(protectedRoute)) {
                this.isCompanyScoped = true;
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.length;
            });

        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
            });

        this._userService.activeCompany$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((company) => {
                this.activeCompany = company;
                this.reRunGuards();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    private isFinanceHidden = (item: FuseNavigationItem): boolean => {
        if (!this.user.companies) {
            return true;
        }

        // if one of the companies has the feature enabled keep nav
        for (const company of this.user.companies) {
            if (companyHasFeature(company, CompanyFeature.Finance)) {
                return false;
            }
        }
        return true;
    };

    private onFinanceClicked = (item: FuseNavigationItem): void => {
        if (companyHasFeature(this.activeCompany, CompanyFeature.Finance)) {
            return;
        }

        // set active company the first one with active billing feature
        for (const company of this.user?.companies || []) {
            if (!companyHasFeature(company, CompanyFeature.Finance)) {
                continue;
            }

            this.setActiveCompany(company._id);
            this._router.navigateByUrl('/finance');
            return;
        }
    };

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    setActiveCompany(companyId: string) {
        this._userService.setActiveCompany(companyId);
    }

    public onDropdownClicked() {
        this.dropdownIcon =
            this.dropdownIcon === 'mat_solid:arrow_drop_down'
                ? 'mat_solid:arrow_drop_up'
                : 'mat_solid:arrow_drop_down';
    }

    public reRunGuards() {
        for (const navItem of this.navigation.compact) {
            switch (navItem.id) {
                case NavItemId.FINANCE:
                    navItem.hidden = this.isFinanceHidden;
                    navItem.function = this.onFinanceClicked;
            }
        }
    }

    public getCurrentModuleNavigationConfigs(): FuseNavigationItem {
        return this.navigation?.compact.find((module: FuseNavigationItem) =>
            this._router.url.includes(module.link)
        );
    }
}
