import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
    IApiResponse,
    IPaymentRequestAttachmentResponse,
    IUpdateInboxItem
} from '../../../app/modules/admin/inbox/inbox.type';
import { GetUserTaskCountDto } from '../../../api/models';
import { TaskApiService } from '../../../api/services';

@Injectable({
    providedIn: 'root'
})
export class TaskService {
    public _taskCount = new BehaviorSubject<GetUserTaskCountDto>(null);
    public taskInfo: ReplaySubject<any> = new ReplaySubject<any>(1);

    constructor(
        private _httpClient: HttpClient,
        private readonly _taskApiService: TaskApiService
    ) {
        this.refreshTaskCount();
    }

    taskCount$(): Observable<GetUserTaskCountDto> {
        return this._taskCount.asObservable();
    }

    refreshTaskCount() {
        this._taskApiService.getTaskCount().subscribe((taskCount) => {
            this._taskCount.next(taskCount);
        });
    }

    updateInboxItem(
        inboxItemId: string,
        inboxItem: IUpdateInboxItem
    ): Observable<any> {
        return this._httpClient.patch(
            `${environment.apiUrl}/task/task-response/${inboxItemId}`,
            inboxItem
        );
    }

    getTaskLink(taskId: string, redirectUrl: string): Observable<any> {
        return this._httpClient.get(
            `${environment.apiUrl}/task/${taskId}/link?redirectUrl=${redirectUrl}`
        );
    }
    getLastTaskLink(redirectUrl: string): Observable<any> {
        return this._httpClient.get(
            `${environment.apiUrl}/task/link?redirectUrl=${redirectUrl}`
        );
    }

    getDownloadDocumentLink(
        taskId: string,
        redirectUrl: string
    ): Observable<IApiResponse<{ url: string; isVerificationLink: boolean }>> {
        return this._httpClient.get<
            IApiResponse<{
                url: string;
                isVerificationLink: boolean;
            }>
        >(
            `${environment.apiUrl}/task/e-sign/doc/${taskId}?redirectUrl=${redirectUrl}`
        );
    }

    getDownloadESignAttachment(
        taskId: string,
        redirectUrl: string
    ): Observable<IApiResponse<{ url: string; isVerificationLink: boolean }>> {
        return this._httpClient.get<
            IApiResponse<{
                url: string;
                isVerificationLink: boolean;
            }>
        >(
            `${environment.apiUrl}/task/e-sign/source-doc/${taskId}?redirectUrl=${redirectUrl}`
        );
    }

    getDownloadAttachmentLink(
        type: string,
        taskId: string,
        fileId: string
    ): Observable<IPaymentRequestAttachmentResponse> {
        return this._httpClient.post<IPaymentRequestAttachmentResponse>(
            `${environment.apiUrl}/task/download/${type}/${taskId}?all=false`,
            {
                fileIds: [fileId]
            }
        );
    }

    uploadRequestedFiles(taskId: string, fileId: string, file: FormData) {
        return this._httpClient.post(
            `${environment.apiUrl}/task/upload/${taskId}/${fileId}`,
            file
        );
    }
}
