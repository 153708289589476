/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum TaskType {
  PtiCheckList = 'PTI-checkList',
  ESign = 'e-sign',
  PtiCalculationProposal = 'pti-calculation-proposal',
  Notice = 'notice',
  GeneralTask = 'generalTask',
  PaymentRequest = 'paymentRequest',
  Survey = 'survey'
}
