<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [appearance]="'compact'"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.compact"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20 mt-3 mb-4">
            <img
                class="w-10"
                src="assets/images/logo/logo.svg"
                alt="Logo image"
            />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div
        class="relative w-full min-h-13 px-6 sm:px-10 z-49 pt-6 pb-2 sm:pt-10 print:hidden"
        [ngClass]="{
            'bg-card border-b':
                getCurrentModuleNavigationConfigs()?.id === NavItemId.PROFILE
        }"
    >
        <!-- Active Company Selector -->
        <div *ngIf="isCompanyScoped">
            <button
                mat-flat-button
                [matMenuTriggerFor]="menu"
                color="primary"
                class="squared-button ml-3"
                (menuClosed)="onDropdownClicked()"
                (menuOpened)="onDropdownClicked()"
            >
                {{ activeCompany.name }}
                <mat-icon
                    iconPositionEnd
                    [svgIcon]="dropdownIcon"
                    style="
                        margin-right: -4px !important;
                        margin-left: 8px !important;
                    "
                />
            </button>
            <mat-menu #menu="matMenu">
                <div *ngFor="let company of user?.companies || []">
                    <button
                        mat-menu-item
                        (click)="setActiveCompany(company._id)"
                        class="py-[6px]"
                        style="min-height: auto !important"
                    >
                        {{ company.name }}
                    </button>
                </div>
            </mat-menu>
        </div>

        <div
            class="flex flex-0 items-center justify-between flex-wrap"
            [ngClass]="{ '-mx-2.5': isScreenSmall }"
        >
            <div class="flex items-center justify-between">
                <!-- Navigation toggle button, shows only in mobile view -->
                <button
                    mat-icon-button
                    (click)="toggleNavigation('mainNavigation')"
                    [ngClass]="{ hidden: !isScreenSmall }"
                >
                    <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                </button>
                <div
                    class="text-secondary text-2xl sm:text-3xl md:text-4xl font-extrabold"
                >
                    {{
                        'app.' + getCurrentModuleNavigationConfigs()?.title
                            | translate
                    }}
                </div>
            </div>
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!--    &lt;!&ndash; Footer &ndash;&gt;-->
    <!--    <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">-->
    <!--        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>-->
    <!--    </div>-->
</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat"></quick-chat>-->
