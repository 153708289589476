import { Injectable } from '@angular/core';
import { NotifierService } from 'gramli-angular-notifier';
import { WebSocketService } from '../../../app/core/web-socket/web-socket.service';
import { TaskEventTypesEnum } from '../../../app/core/web-socket/web-socket.types';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(
        private readonly notifier: NotifierService,
        private readonly webSocketService: WebSocketService,
        private readonly translationService: TranslateService
    ) {
        this.webSocketService.taskNotification.subscribe((res) => {
            if (res) {
                this.showNotification(
                    'info',
                    this.translationService.instant(
                        'app.' + TaskEventTypesEnum[res.type]
                    )
                );
            }
        });
    }

    public showNotification(
        type: 'info' | 'success' | 'warning' | 'error',
        message: string
    ): void {
        this.notifier.notify(type, message);
    }

    public hideAllNotifications(): void {
        this.notifier.hideAll();
    }
}
