import { Component, OnInit } from '@angular/core';
import { TaskService } from '../../../../@fuse/services/pti/task.service';
import { FirmData } from './accounting-firm.types';
import { User } from '../../../core/user/user.types';
import { UserService } from '../../../core/user/user.service';
import { NotificationService } from '../../../../@fuse/services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { WebSocketService } from '../../../core/web-socket/web-socket.service';
import { EntityAssociation } from '../../../../api/models';

@Component({
    selector: 'app-accounting-firm',
    templateUrl: './accounting-firm.component.html'
})
export class AccountingFirmComponent implements OnInit {
    public isGetLink = true;
    public taskInformation;
    userAssociations: EntityAssociation[] = [];
    firmName: string;
    public user;
    isLoading = true;
    constructor(
        private readonly _taskService: TaskService,
        private readonly _userService: UserService,
        private readonly notificationService: NotificationService,
        private readonly translationService: TranslateService,
        private readonly router: Router,
        private readonly webSocketService: WebSocketService
    ) {}

    ngOnInit(): void {
        this.getTaskInfo();
        this.getUserAssociations();

        this._userService.user$.pipe().subscribe((user: User) => {
            this.user = user;
            if (this.userAssociations) {
                this.setUserFirmName();
            }
        });
        this.webSocketService.totalOutstandingCount.subscribe(
            (outstandingCount) => {
                this.taskInformation = {
                    totalOutstandingTask: outstandingCount?.outstandingTask,
                    totalOutstandingItem: outstandingCount?.outstandingItem,
                    totalOutstandingNotice: outstandingCount?.outstandingNotice,
                    totalPTITasks: outstandingCount?.outstandingPTITask
                };
            }
        );
    }

    getUserAssociations() {
        this._userService.getAssociations().subscribe({
            next: (userAssociations) => {
                this.userAssociations = userAssociations;
                if (this.user) {
                    this.setUserFirmName();
                }
            },
            error: (error) => {
                this.notificationService.showNotification(
                    'error',
                    this.translationService.instant(
                        'app.ERROR_GET_ASSOCIATIONS'
                    )
                );
            }
        });
    }

    isUserAssociationActive(): boolean {
        return this._userService.isUserAssociationActive();
    }

    setUserFirmName() {
        const userAssociation = this.userAssociations.find(
            (userAssociation) => userAssociation.entityId === this.user._id
        );
        this.firmName = userAssociation?.accountonAssociation?.lawFirm?.name;
    }

    private getTaskInfo(): void {
        this._taskService.taskCount$().subscribe({
            next: (taskInfo) => {
                this.taskInformation = taskInfo;
                this.isLoading = false;
            },
            error: (err) => {
                if (err.error.error === 'USER_ASSOCIATION_NOT_FOUND') {
                    return;
                }
                this.notificationService.showNotification(
                    'error',
                    this.translationService.instant(
                        'app.' + (err.error?.error || 'SOMETHING_WENT_WRONG')
                    )
                );
            }
        });
    }

    goToLastPTI() {
        this.isGetLink = false;
        const windowReference = window.open('', '_blank');
        this._taskService.getLastTaskLink(window.location.href).subscribe({
            next: (res) => {
                this.router.navigate([]).then(() => {
                    if (res.isVerificationLink) {
                        windowReference.close();
                        window.open(res.url, '_self');
                    } else {
                        windowReference.location = res.url;
                    }
                    this.isGetLink = true;
                });
            },
            error: (err) => {
                windowReference.close();
                this.isGetLink = true;
                this.notificationService.showNotification(
                    'error',
                    this.translationService.instant(
                        'app.' + (err.error?.error || 'SOMETHING_WENT_WRONG')
                    )
                );
            }
        });
    }
}
