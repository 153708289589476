import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, BehaviorSubject, tap, pairwise } from 'rxjs';
import { UpdatePassword, User } from 'app/core/user/user.types';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import {
    CompanyWithPermissions,
    EntityAssociation,
    Permission,
    UserWithPermissions
} from '../../../api/models';
import { UserApiService } from '../../../api/services';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user = new BehaviorSubject<UserWithPermissions>(null);

    private _activeCompany = new BehaviorSubject<CompanyWithPermissions>(null);

    private _userAssociations = new BehaviorSubject<EntityAssociation[]>(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _router: Router,
        private readonly _userApiService: UserApiService
    ) {
        this._user.subscribe((user) => {
            if (!user) {
                return;
            }
            const activeCompanyId =
                window.localStorage.getItem('activeCompanyId');

            const activeCompany =
                user.companies.find((c) => c._id === activeCompanyId) ||
                user.companies[0] ||
                undefined;

            this.setActiveCompany(activeCompany?._id);
        });

        // reload navigation when activeCompany changes
        this._activeCompany
            .asObservable()
            .pipe(pairwise())
            .subscribe(([oldCompany, newCompany]) => {
                if (
                    oldCompany &&
                    (!newCompany || oldCompany._id !== newCompany._id)
                ) {
                    this._router.navigate([this._router.url]);
                }
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    get activeCompany$(): Observable<CompanyWithPermissions> {
        return this._activeCompany.asObservable();
    }

    get userAssociations$(): Observable<EntityAssociation[]> {
        return this._userAssociations.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<UserWithPermissions> {
        return this._userApiService.getUser().pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    getAssociations(): Observable<EntityAssociation[]> {
        if (this._userAssociations.getValue()) {
            return this.userAssociations$;
        }
        return this._userApiService.getUserAssociations().pipe(
            tap((userAssociations) => {
                this._userAssociations.next(userAssociations);
            })
        );
    }

    /**
     * Will access user's associations in memory to return
     * the correct one by id or undefined
     */
    getAssociationById(associationId: string): EntityAssociation {
        const userAssociations = this._userAssociations.getValue();
        if (!userAssociations || !associationId) {
            return undefined;
        }

        // search for the association with kamigo id equal to the user id
        return userAssociations.find(
            (userAssociation) => userAssociation.entityId === associationId
        );
    }

    /**
     * Get the associations of the user itself
     */
    getUserAssociation(): EntityAssociation {
        const user = this._user.getValue();
        return this.getAssociationById(user._id);
    }

    /**
     * If the user's association itself is active
     */
    isUserAssociationActive(): boolean {
        const userAssociation = this.getUserAssociation();

        return !!userAssociation?.accountonAssociation;
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient
            .patch<User>(`${environment.apiUrl}/user`, user)
            .pipe(
                map((response) => {
                    this._user.next(response);
                })
            );
    }

    updatePassword(updatePassword: UpdatePassword): Observable<any> {
        return this._httpClient.post(
            `${environment.apiUrl}/user/reset-password`,
            updatePassword
        );
    }

    logOut(): Observable<any> {
        return this._httpClient.post(`${environment.apiUrl}/user/logout`, {});
    }

    fileUploadEvent(file: FormData): Observable<any> {
        return this._httpClient.post(`${environment.apiUrl}/storage`, file);
    }

    changeEmail(emailObj: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.patch(
            `${environment.apiUrl}/user/validate/change-mail`,
            emailObj
        );
    }

    hasPermission(companyId: string, permission: Permission): boolean {
        const userWithPermissions =
            this._user.getValue() as unknown as UserWithPermissions;

        const company = userWithPermissions.companies.find(
            (company) => company._id === companyId
        );

        return !!company?.permissions?.includes(permission);
    }

    setActiveCompany(id: string) {
        const user = this._user.getValue();
        const activeCompany = user.companies.find((c) => c._id === id) || null;
        window.localStorage.setItem(
            'activeCompanyId',
            activeCompany?._id || ''
        );
        this._activeCompany.next(activeCompany);
    }
}
