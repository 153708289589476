import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SignUpGuard 
{
    /**
     * Constructor
     */
    constructor(private _router: Router) {}

    /**
     * Can match
     *
     * @param route
     * @param segments
     */
    canMatch(
        route: Route,
        segments: UrlSegment[]
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (segments[0].path === 'sign-up') {
            const token = window.location.href.split('?token=')[1];
            if (!token && environment.enableSignUp) {
                return true;
            }
            else {
                return false;
            }
        }
        }
}
