/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { get2FaData } from '../fn/two-factor-auth/get-2-fa-data';
import { Get2FaData$Params } from '../fn/two-factor-auth/get-2-fa-data';
import { GetUser2FaDto } from '../models/get-user-2-fa-dto';
import { ILoginResponse } from '../models/i-login-response';
import { loginWithTfa } from '../fn/two-factor-auth/login-with-tfa';
import { LoginWithTfa$Params } from '../fn/two-factor-auth/login-with-tfa';
import { verifyUser2Fa } from '../fn/two-factor-auth/verify-user-2-fa';
import { VerifyUser2Fa$Params } from '../fn/two-factor-auth/verify-user-2-fa';

@Injectable({ providedIn: 'root' })
export class TwoFactorAuthApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `get2FaData()` */
  static readonly Get2FaDataPath = '/tfa';

  /**
   * Return the qrcode binary data for enable 2fa
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get2FaData()` instead.
   *
   * This method doesn't expect any request body.
   */
  get2FaData$Response(params?: Get2FaData$Params, context?: HttpContext): Observable<StrictHttpResponse<GetUser2FaDto>> {
    return get2FaData(this.http, this.rootUrl, params, context);
  }

  /**
   * Return the qrcode binary data for enable 2fa
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get2FaData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get2FaData(params?: Get2FaData$Params, context?: HttpContext): Observable<GetUser2FaDto> {
    return this.get2FaData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetUser2FaDto>): GetUser2FaDto => r.body)
    );
  }

  /** Path part for operation `verifyUser2Fa()` */
  static readonly VerifyUser2FaPath = '/tfa/verify';

  /**
   * Enable/Disable the two-factor authentication
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyUser2Fa()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyUser2Fa$Response(params: VerifyUser2Fa$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return verifyUser2Fa(this.http, this.rootUrl, params, context);
  }

  /**
   * Enable/Disable the two-factor authentication
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `verifyUser2Fa$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyUser2Fa(params: VerifyUser2Fa$Params, context?: HttpContext): Observable<void> {
    return this.verifyUser2Fa$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `loginWithTfa()` */
  static readonly LoginWithTfaPath = '/tfa/login';

  /**
   * Login with TFA with 2fa code verification
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `loginWithTfa()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginWithTfa$Response(params: LoginWithTfa$Params, context?: HttpContext): Observable<StrictHttpResponse<ILoginResponse>> {
    return loginWithTfa(this.http, this.rootUrl, params, context);
  }

  /**
   * Login with TFA with 2fa code verification
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `loginWithTfa$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  loginWithTfa(params: LoginWithTfa$Params, context?: HttpContext): Observable<ILoginResponse> {
    return this.loginWithTfa$Response(params, context).pipe(
      map((r: StrictHttpResponse<ILoginResponse>): ILoginResponse => r.body)
    );
  }

}
