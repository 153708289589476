/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DownloadAttachmentDto } from '../../models/download-attachment-dto';
import { DownloadFilesTypes } from '../../models/download-files-types';

export interface DownloadAttachments$Params {
  type: DownloadFilesTypes;
  taskId: string;
  all: boolean;
      body: DownloadAttachmentDto
}

export function downloadAttachments(http: HttpClient, rootUrl: string, params: DownloadAttachments$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
  const rb = new RequestBuilder(rootUrl, downloadAttachments.PATH, 'post');
  if (params) {
    rb.path('type', params.type, {});
    rb.path('taskId', params.taskId, {});
    rb.query('all', params.all, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      }>;
    })
  );
}

downloadAttachments.PATH = '/task/download/{type}/{taskId}';
