import { TaskDataDto } from '../../../api/models';

export enum TaskEventTypesEnum {
    createTask = 'CREATE_TASK',
    updateTask = 'UPDATE_TASK',
    deleteTask = 'DELETE_TASK'
}

export interface OutstandingCount {
    outstandingTask?: number;
    outstandingNotice?: number;
    outstandingItem: number;
    outstandingPTITask: number;
}

export interface ItemEventType {
    type: string;
    taskData: Array<TaskDataDto>;
    totalOutstandingItem: number;
    totalOutstandingNotice: number;
    totalOutstandingTask: number;
    totalCompletedItem: number;
    totalCompletedNotice: number;
    totalCompletedTask: number;
    totalPTITasks: number;
    entityId?: string;
    entityType?: string;
}
