/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EmailConfirmationDto } from '../../models/email-confirmation-dto';
import { ILoginResponse } from '../../models/i-login-response';

export interface VerifyEmailConfirmation$Params {

/**
 * Pass the signUp token
 */
  Authorization: string;
      body: EmailConfirmationDto
}

export function verifyEmailConfirmation(http: HttpClient, rootUrl: string, params: VerifyEmailConfirmation$Params, context?: HttpContext): Observable<StrictHttpResponse<ILoginResponse>> {
  const rb = new RequestBuilder(rootUrl, verifyEmailConfirmation.PATH, 'post');
  if (params) {
    rb.header('Authorization', params.Authorization, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ILoginResponse>;
    })
  );
}

verifyEmailConfirmation.PATH = '/user/email-confirmation';
