import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    /**
     * Constructor
     */
    constructor(private _authService: AuthService, private _router: Router) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can match
     *
     * @param route
     * @param segments
     */
    canMatch(
        route: Route,
        segments: UrlSegment[]
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this._check(segments);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param segments
     * @private
     */
    private _check(segments: UrlSegment[]): Observable<boolean | UrlTree> {
        return this._authService.check().pipe(
            switchMap((authenticated) => {
                if (!authenticated) {
                    const navigation = this._router.getCurrentNavigation();

                    // include optional query params in redirect URL
                    const currentQueryParams =
                        navigation?.extractedUrl?.queryParams || {};

                    let redirectURL = `/${segments.join('/')}`;
                    if (Object?.keys(currentQueryParams)?.length) {
                        redirectURL +=
                            '?' +
                            new URLSearchParams(currentQueryParams).toString();
                    }

                    const urlTree = this._router.createUrlTree(['sign-in'], {
                        queryParams: {
                            redirectURL
                        },
                        queryParamsHandling: 'merge'
                    });

                    return of(urlTree);
                }

                // Allow the access
                return of(true);
            })
        );
    }
}
